<template>
  <div class="syllabus">
    <Steps :current="step" :link="link" :mode="mode" :id="id"></Steps>
    <div class="container p-4 editCourse__container">

      <div class="row">
        <div class="syllabus__group" v-for="(item, i) in syllabus" :key="item.id">
          <div class="syllabus__box syllabus__chapter d-flex align-items-center p-3 mb-2 position-relative">
            <span>章節 {{ i + 1 }}</span>
            <span class="text-start mx-2" style="flex: 1 1 0%;display: inline-block;height: 1.5rem;line-height: 1.5rem;" v-if="tempSyllabus.id !== item.id"
              @dblclick.prevent="editChapter(item);Showtooltip = ''"
              @mouseover="Showtooltip = item.id"
              @mouseleave="Showtooltip = ''">
              {{ item.title }}
            </span>
            <span class="position-absolute edit-tooltip" v-if="Showtooltip === item.id">
              點擊兩下以編輯
            </span>
            <input type="text" style="flex: 1 1 0%;border:none;"
              v-if="tempSyllabus.id == item.id" class="mx-2 px-0"
              v-model="tempSyllabus.title"
              @change="finishChapter;change = true"
              >
            <!-- <span class="mx-2"><i class="fas fa-check" @click.prevent="finishChapter"></i></span> -->
            <span class="mx-2"><i class="fas fa-plus" @click.prevent="addChapter(i)"></i></span>
            <span class="mx-2"><i class="fas fa-trash" @click.prevent="deleteChapter(item)"></i></span>
          </div>
          <div class="syllabus__box syllabus__section d-flex align-items-center p-3 ms-5 mb-2 position-relative"
            v-for="(sec, j) in item.section" :key="sec.id">
            <span class="mx-2" v-if="sec.lock"
              @click.prevent="editSection(item, sec);tempSection.lock = false;finishSection(item.id)">
                <i class="fas fa-lock me-1"></i>
            </span>
            <span class="mx-2" v-if="!sec.lock"
              @click.prevent="editSection(item, sec);tempSection.lock = true;finishSection(item.id)">
              <i class="fas fa-unlock"></i>
            </span>
            <span v-if="!sec.isHomeWork">單元 {{ j + 1 }}</span>
            <span v-if="sec.isHomeWork">作業</span>
            <span class="text-start mx-2"
              @mouseover="Showtooltip = sec.id"
              @mouseleave="Showtooltip = ''"
              style="flex: 1 1 0%;display: inline-block;height: 1.5rem;line-height: 1.5rem;" v-if="tempSyllabus.id !== item.id || tempSection.id !== sec.id"
              @dblclick.prevent="editSection(item, sec);Showtooltip = ''">
              {{ sec.title }}
            </span>
            <span v-if="Showtooltip == sec.id" class="position-absolute edit-tooltip">
              點擊兩下以編輯
            </span>
            <input type="text" style="flex: 1 1 0%;border:none;"
              v-if="tempSyllabus.id == item.id && tempSection.id === sec.id"
              class="mx-2 px-0"
              v-model="tempSection.title"
              @change="finishSection(item.id);change = true">
            <span class="mx-2" data-bs-target="#editChapterModal" data-bs-toggle="modal"
              @click.prevent="openModal(item.id, sec.id, sec.video, sec.lock, sec.desc, sec.time, sec.title)">
              <i class="fas fa-edit"></i>
            </span>
            <!-- <span class="mx-2"><i class="fas fa-check" @click.prevent="finishSection(item.id)"></i></span> -->
            <span class="mx-2"><i class="fas fa-plus" @click.prevent="addSection(item.id, j)"></i></span>
            <span class="mx-2"><i class="bi bi-card-heading" @click.prevent="addHomework(item.id, j)"></i></span>
            <span class="mx-2"><i class="fas fa-trash" @click.prevent="deleteSection(item, sec)"></i></span>
            <!-- <span class="mx-2"><i class="fas fa-plus" @click.prevent="addSub=sec.id"></i></span> -->
            <!-- <Test v-if="addSub==sec.id"></Test> -->
          </div>
        </div>
      </div>
      <span v-if="emptyError">{{ emptyError }}</span>
      <div class="d-flex justify-content-start align-items-center editBox" ref="editBox">

        <button class="btn btn-main mt-3 w-25 me-2" v-if="mode == 'launch'" @click.prevent="toLastStep">上一步</button>

        <button class="btn btn-main mt-3 w-25" @click.prevent="save">{{ mode == 'launch'? '下一步' : '儲存' }}</button>
        <a class="editCourse__back mt-3 w-50 text-start" style="margin-left: 1rem;" v-if="mode == 'edit'" @click.prevent="backToMyCourse">
          返回至我的課程頁<i class="fas fa-chevron-right ms-1"></i>
        </a>
      </div>
      <!-- 浮動buttons -->
      <div class="d-flex justify-content-start align-items-center editCourse__saveFixed p-4" v-if="showBtn">

        <button class="btn btn-main mt-3 w-25 me-2" v-if="mode == 'launch'" @click.prevent="toLastStep">上一步</button>

        <button class="btn btn-main mt-3" @click.prevent="save">{{ mode == 'launch'? '下一步' : '儲存' }}</button>
        <a class="editCourse__back mt-3 text-start" style="margin-left: 1rem;" v-if="mode == 'edit'" @click.prevent="backToMyCourse">
          返回至我的課程頁<i class="fas fa-chevron-right ms-1"></i>
        </a>
      </div>

    </div>
    <div class="modal fade editChapter" id="editChapterModal" tabindex="-1" aria-labelledby="editChapterModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-xl modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header border-0 pb-0">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
            <div class="modal-body text-start">
              <h3 class="text-start fw-bold mb-2 evaluation__title" style="font-size: 1.2rem;">影片位址</h3>
                <ValidationProvider rules='required|http' v-slot='{ errors , classes }'>
                  <div :class="classes" class="position-relative">
                    <input type="text" placeholder="https://player.vimeo.com/video/"
                      class="editChapter__input"
                      v-model="tempLink"
                      readonly
                      @focus="editing = true">
                    <span class="text-danger mt-1">{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              <!-- <span v-if="editing == false" @dblclick="editing = true">{{ tempLink }}</span> -->
                <!-- <button class="btn btn-main d-block mt-4">開始上傳檔案</button> -->
                <form class="mt-4 d-flex" @submit.prevent="testUpload()">
                  <label for="file" class="form-label"></label>
                  <input class="form-control w-50" type="file" id="file" name="file_data" ref="file" @change="testCreateVideo()">
                  <input type="submit" name="submit" value="上傳" class="btn btn-main" v-if="readyUpload" :disabled="disabled">
                </form>
                <div class="d-flex align-items-center mt-3" v-if="startCreate">
                  <div class="upload__progress me-2">
                    <div class="upload__progressBar" :style="{ 'width': percentage + '%'}"></div>
                  </div>
                  <p>{{ percentage }}% 上傳中，請勿關閉視窗</p>
                </div>

                <div class="mt-4">
                  <h3 class="mb-2 fw-bold">影片長度</h3>
                  <div>
                    <input type="number" name="hour" size="2" max="10" min="0"
                      class="form-control d-inline-block me-2"
                      style="width: unset;"
                      placeholder="0"
                      v-model="hour"
                      readonly
                      >
                    <span class="me-3">小時</span>
                    <input type="number" name="min" size="2" max="60" min="0"
                      class="form-control d-inline-block me-2"
                      style="width: unset;"
                      placeholder="0"
                      ref="min"
                      v-model="min"
                      readonly
                      >
                    <span class="me-3">分鐘</span>
                    <input type="number" name="sec" size="2" max="60" min="0"
                      class="form-control d-inline-block me-2"
                      style="width: unset;"
                      placeholder="0"
                      ref="sec"
                      v-model="sec"
                      readonly
                      >
                    <span>秒</span>
                  </div>
                  <span class="invalid text-danger mt-1" v-if="timeError" @change="timeError = ''">{{ timeError }}</span>
                </div>
                <h3 class="mt-4 fw-bold">內容描述</h3>
                <quill-editor class="ql-editor"
                  v-model="tempContent"
                  ref="myQuillEditor"
                  :options="editorOption"
                  >
                </quill-editor>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">取消</button>
              <button type="button" class="btn btn-main" data-bs-dismiss="modal" @click.prevent="saveLink">儲存</button>
            </div>
        </div>
      </div>
    </div>
    <!-- <div v-for="(item, i) in syllabus" :key="item.id">
      <Test :data="item" :i='i' :pi="i" @finishChapter="finished"></Test>
    </div> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import * as tus from 'tus-js-client';
import Steps from '../components/Steps';
import Test from '../components/test';

const Swal = require('sweetalert2');

/*  [{
    id: '',
    title: '',
    video: '',
    desc: '',
    lock: true,
    section: [
      {
        id: '',
        title: '',
        video: '',
        desc: '',
        lock: true,
      },
      {
        id: '',
        title: '',
        video: '',
        desc: '',
        lock: true,
      }
    ]
  }] */

export default {
  name: 'Edit2',
  data() {
    return {
      step: 2,
      link: '/dashboard/edit_course/syllabus',
      editorOption: {
        modules: {
          toolbar: {
            container: [
              [{ header: [1, 2, 3, 4, 5, 6, false] }], ['bold'],
              [{ list: 'ordered' }, { list: 'bullet' }], ['link', 'image'],
            ],
          },
        },
        theme: 'snow',
      },
      tempSyllabus: {
        id: '',
        title: '',
        section: [{
          id: Math.floor(Date.now() + 10),
          title: '',
          videoUrl: '',
          videoId: '',
          desc: '',
          lock: true,
          time: '',
          isHomeWork: false,
        }],
      },
      tempSection: {
        id: Math.floor(Date.now() + 10),
        title: '',
        videoUrl: '',
        videoId: '',
        desc: '',
        lock: true,
        time: '',
        isHomeWork: false,
      },
      tempSyll: [],
      chapterId: '',
      sectionId: '',
      tempLink: '',
      editing: false,
      templock: true,
      addSub: '',
      tempContent: '',
      hour: 0,
      min: 0,
      sec: 0,
      timeError: '',
      Showtooltip: null,
      mode: 'launch',
      goNext: false,
      emptyError: '',
      id: '',
      change: false,
      showBtn: false,
      startCreate: false,
      uploadLink: '',
      vimeoUrl: '',
      percentage: '',
      readyUpload: false,
      disabled: false,
      tempTitle: '',
    };
  },
  components: {
    Steps,
    Test,
  },
  methods: {
    testCreateVideo() {
      const vm = this;
      const uploadedFile = this.$refs.file.files[0];
      if (!this.$refs.file.files[0]) {
        this.readyUpload = false;
        return;
      }
      vm.$log.debug(uploadedFile);

      this.$http.post('https://apiedu.drmaster.mypeoplevol.com/Vimeo', {
        VideoSize: uploadedFile.size,
      }, {
        headers: {
          Accept: '*/*',
        },
      }).then((res) => {
        this.readyUpload = true;
        vm.$log.debug(res.data);
        this.uploadLink = res.data.data.uploadLink;
        this.vimeoUrl = res.data.data.videoEmbedUrl;
      }).catch((err) => {
        vm.$log.debug(err.response);
        this.readyUpload = false;
      });
    },
    testUpload() {
      const vm = this;
      const uploadedFile = this.$refs.file.files[0];
      vm.$log.debug(uploadedFile);
      vm.disabled = true;
      if (!this.$refs.file.files[0]) {
        vm.startCreate = false;
        vm.disabled = false;
        return;
      }
      vm.startCreate = true;
      var upload = new tus.Upload(uploadedFile, {
        uploadUrl: this.uploadLink,
        onError(error) {
          vm.$log.debug(`Failed because: ${error}`);
          vm.disabled = false;
        },
        onProgress(bytesUploaded, bytesTotal) {
          vm.percentage = (bytesUploaded / bytesTotal * 100).toFixed(0);
          vm.$log.debug(bytesUploaded, bytesTotal, `${vm.percentage}%`);
        },
        onSuccess() {
          vm.$log.debug('Download %s from %s', upload.file.name, upload);

          vm.startCreate = false;
          vm.$log.debug('Download %s from %s', upload.file.name, upload);
          vm.tempLink = vm.vimeoUrl;
          vm.disabled = false;
        // vm.$http.put(`https://api.vimeo.com/videos/${vm.vimeoUrl.split('/').pop()}/privacy/domains/localhost`, {
          // privacy: { embed: "whitelist" }
          // }, {
          //   headers: {
          //     'Authorization': `bearer 6e21a5e7a50f2160076af90e1f85ebea`,
          //   }
          // }).then(res => {
          //   vm.$log.debug(res.data);
          //   return vm.$http.get(`https://api.vimeo.com/videos/${vm.vimeoUrl.split('/').pop()}?fields=transcode.status`,
          //   {
          //   headers: {
          //     'Authorization': `bearer 6e21a5e7a50f2160076af90e1f85ebea`,
          //   }
          // });
          // // 顯示文字：正在轉檔
          // }).then(res => {
          //   vm.$log.debug(res.data);
          //   return vm.$http.get(`https://api.vimeo.com/videos/${vm.vimeoUrl.split('/').pop()}?fields=duration`,
          //   {
          //   headers: {
          //     'Authorization': `bearer 6e21a5e7a50f2160076af90e1f85ebea`,
          //   }
          // });
          // }).then(res => {
          //   vm.startCreate = false;
          //   vm.$log.debug("Download %s from %s", upload.file.name, upload)
          //   vm.tempLink = vm.vimeoUrl;
          //   vm.disabled = false;
          //   vm.$log.debug(res.data);
          // }).catch(err => {
          //   vm.$log.debug(err);
          // })
        },
      });
      // Start the upload
      upload.start();
    },
    addChapter(idx) {
      this.tempSyll.splice(idx + 1, 0, {
        id: Math.floor(Date.now()),
        title: '',
        section: [{
          id: Math.floor(Date.now() + 10),
          title: '',
          videoUrl: '',
          videoId: '',
          desc: '',
          lock: true,
          time: '',
          isHomeWork: false,
        }],
      });
      this.$store.dispatch('syllabusModule/getSyllData', this.tempSyll);
    },
    editChapter(item) {
      this.tempSyllabus = item;
    },
    editSection(item, sec) {
      this.tempSyllabus = item;
      this.tempSection = sec;
    },
    save() {
      const vm = this;
      vm.goNext = true;
      // get syllabus data from local
      // this.getDatafromLocal();

      // handle data format
      const chapters = this.tempSyll.map((el, i) => {
        if (!el.title) {
          vm.emptyError = '提醒：似乎有內容未填～';
        }
        return { title: el.title, chaperItem: el.section };
      });
      chapters.forEach((item, idx) => {
        const chapterArr = item.chaperItem.map((el, i) => {
          if (!el.title && !el.video && !el.desc) {
            vm.emptyError = '提醒：似乎有內容未填～';
          }
          return {
            title: el.title, videoUrl: el.video, Lock: el.lock, Content: el.desc, duration: el.time,
          };
        });
        item.chaperItem = [...chapterArr];
      });
      this.$store.dispatch('syllabusModule/getSyllData', this.tempSyll);
      vm.$log.debug(chapters);
      if (vm.mode == 'edit') {
        const data = { ...vm.course, chapter: chapters };
        vm.$log.debug(data);
        vm.$http.put('https://apiedu.drmaster.mypeoplevol.com/Course', data, {
          headers: {
            Accept: '*/*',
            'Content-Type': 'application/json',
            Authorization: `bearer ${vm.userAuth}`,
          },
        }).then((res) => {
          vm.goNext = true;
          // localStorage.removeItem('syllabus');
          // this.tempSyll = [];
          // this.$store.dispatch('syllabusModule/resetSyllData');
          Swal.fire(
            '儲存成功',
            `${res.data.message}`,
            'success',
          );
        }).catch((err) => {
          vm.$log.debug(err.response);
          if (err.response) {
            Swal.fire(
              '',
              `${err.response.data.message}`,
              'info',
            );
          } else {
            Swal.fire(
              '錯誤',
              '請稍候再試',
              'info',
            );
          }
        });
      } else {
        // this.getDatafromLocal();
        // let course = JSON.parse(localStorage.getItem('Course'));
        // course.chapter = chapters;
        // localStorage.setItem('Course', JSON.stringify(course));
        this.$store.dispatch('courseModule/setCourseData', { chapter: chapters });
        this.$store.dispatch('syllabusModule/getSyllData', this.tempSyll);
        console.log(this.tempSyll);
        vm.$router.push('/dashboard/edit_course/pricing');
      }
    },
    toLastStep() {
      const vm = this;
      vm.goNext = true;
      vm.$router.push('/dashboard/edit_course/basic');
    },
    addSection(id, idx) {
      let i;
      this.tempSyll.forEach((el, idx) => {
        if (el.id === id) {
          i = idx;
        }
      });
      this.tempSyll[i].section.splice(idx + 1, 0, {
        id: Math.floor(Date.now()),
        title: '',
        video: '',
        desc: '',
        lock: true,
        time: '',
        isHomeWork: false,
      });
      this.$store.dispatch('syllabusModule/getSyllData', this.tempSyll);
    },
    addHomework(id, idx) {
      let i;
      this.tempSyll.forEach((el, idx) => {
        if (el.id === id) {
          i = idx;
        }
      });
      this.tempSyll[i].section.splice(idx + 1, 0, {
        id: Math.floor(Date.now()),
        title: '',
        video: '',
        desc: '',
        lock: true,
        time: '',
        isHomeWork: true,
      });
      this.$store.dispatch('syllabusModule/getSyllData', this.tempSyll);
    },
    finishChapter() {
      let i;
      this.tempSyll.forEach((el, idx) => {
        if (el.id === this.tempSyllabus.id) {
          i = idx;
        }
      });
      this.tempSyll[i] = this.tempSyllabus;
      this.$store.dispatch('syllabusModule/getSyllData', this.tempSyll);
      // localStorage.setItem('syllabus', JSON.stringify(this.syllabus));
      console.log(this.tempSyll);
    },
    finishSection(id) {
      let i;
      let j;
      this.$log.debug(id);
      this.tempSyll.forEach((el, idx) => {
        this.$log.debug(el.id);
        if (el.id === id) {
          i = idx;
        }
      });
      this.$log.debug(i);
      this.tempSyll[i].section.forEach((el, idx) => {
        if (el.id === this.tempSection.id) {
          j = idx;
        }
      });
      this.tempSyll[i].section[j] = this.tempSection;
      // localStorage.setItem('syllabus', JSON.stringify(this.syllabus));
      this.$store.dispatch('syllabusModule/getSyllData', this.tempSyll);
      // this.getDatafromLocal();
      this.$log.debug(this.syllabus);
    },
    deleteChapter(item) {
      let i;
      this.tempSyll.forEach((el, idx) => {
        if (el.id === item.id) {
          i = idx;
        }
      });
      if (this.tempSyll.length == 1) {
        return;
      }
      this.tempSyll.splice(i, 1);
      // localStorage.setItem('syllabus', JSON.stringify(this.syllabus));
      this.$store.dispatch('syllabusModule/getSyllData', this.tempSyll);
      // this.getDatafromLocal();
      this.$log.debug(this.syllabus);
    },
    deleteSection(item, sec) {
      let i;
      let j;
      this.tempSyll.forEach((el, idx) => {
        this.$log.debug(item.id);
        if (el.id === item.id) {
          i = idx;
        }
      });
      this.$log.debug(i);
      this.tempSyll[i].section.forEach((el, idx) => {
        if (el.id === sec.id) {
          j = idx;
        }
      });
      this.tempSyll[i].section.splice(j, 1);
      // localStorage.setItem('syllabus', JSON.stringify(this.syllabus));
      // this.getDatafromLocal();
      this.$store.dispatch('syllabusModule/getSyllData', this.tempSyll);
      this.$log.debug(this.syllabus);
    },
    openModal(chapterId, sectionId, link, lock, content, time, title) {
      this.chapterId = chapterId;
      this.sectionId = sectionId;
      this.tempLink = link;
      this.templock = lock;
      this.tempContent = content;
      this.tempTitle = title;
      this.$refs.file.value = null;
      this.formatTime(time);
      this.$log.debug(this.tempLink);
    },
    saveLink() {
      this.percentage = '';
      let i;
      let j;
      this.tempSyll.forEach((el, idx) => {
        if (el.id === this.chapterId) {
          i = idx;
        }
      });
      this.$log.debug(i);
      this.tempSyll[i].section.forEach((el, idx) => {
        if (el.id === this.sectionId) {
          j = idx;
        }
      });
      let hr;
      let min;
      let sec;
      let time = '';
      if (isNaN(this.hour)) {
        hr = 0;
      } else {
        hr = this.hour;
      }
      if (isNaN(this.min)) {
        min = 0;
      } else {
        min = this.min;
      }
      if (isNaN(this.sec)) {
        sec = 0;
      } else {
        sec = this.sec;
      }
      this.$log.debug(hr, min, sec);
      hr = hr < 10 ? `0${hr}` : `${hr}`;
      min = min < 10 ? `0${min}` : `${min}`;
      sec = sec < 10 ? `0${sec}` : `${sec}`;
      time = `${hr}:${min}:${sec}`;
      this.$log.debug(hr, min, sec);

      if (time === '00:00:00' || time === '00:0:00') {
        this.timeError = '請填寫影片長度';
        return;
      }
      this.tempSyll[i].section[j].video = this.tempLink;
      this.tempSyll[i].section[j].lock = this.templock;
      this.tempSyll[i].section[j].desc = this.tempContent;
      this.tempSyll[i].section[j].time = time;
      this.$log.debug(time);
      // localStorage.setItem('syllabus', JSON.stringify(this.syllabus));
      // this.getDatafromLocal();
      this.$store.dispatch('syllabusModule/getSyllData', this.tempSyll);
    },
    formatTime(time) {
      const timeArr = time.split(':');
      if (timeArr.length == 2) {
        this.hour = 0;
        this.min = Number(timeArr[0]);
        this.sec = Number(timeArr[1]);
      } else {
        this.hour = Number(timeArr[0]);
        this.min = Number(timeArr[1]);
        this.sec = Number(timeArr[2]);
      }
    },
    finished(idx, pi, parentItem, obj) {
      this.syllabus[idx] = obj;
      this.$log.debug(obj);
      localStorage.setItem('syllabus', JSON.stringify(this.syllabus));
      this.getDatafromLocal();
      this.$log.debug(this.syllabus);
    },
    handleHour(num) {
      this.$log.debug(this.hour);
      if (num.toString().length > 2) {
        this.$log.debug(num);
        num = Number(Array.from(num.toString()).slice(-2).join(''));
        this.hour = num;
      }
      if (num < 0) {
        this.hour = 0;
      }
      this.$log.debug(this.hour);
    },
    handleMin(num) {
      this.$log.debug(this.min);
      if (num > 59) {
        this.min = 0;
      }
      if (num.toString().length > 2) {
        this.$log.debug(num);
        num = Number(Array.from(num.toString()).slice(-2).join(''));
        if (num > 59) {
          this.min = 0;
        } else {
          this.min = num;
        }
      }
      if (num < 0) {
        this.min = 0;
      }
      this.$log.debug(this.min);
    },
    handleSec(num) {
      this.$log.debug(this.sec);
      if (num > 59) {
        this.sec = 0;
      }
      if (num.toString().length > 2) {
        this.$log.debug(num);
        num = Number(Array.from(num.toString()).slice(-2).join(''));
        if (num > 59) {
          this.sec = 0;
        } else {
          this.sec = num;
        }
      }
      if (num < 0) {
        this.sec = 0;
      }
      this.$log.debug(this.sec);
    },
    getDatafromLocal() {
      this.syllabus = JSON.parse(localStorage.getItem('syllabus')) || [];
    },
    getEditData() {
      this.$http.get(`https://apiedu.drmaster.mypeoplevol.com/Course?CourseId=${this.id}`, {
        headers: {
          Accept: '*/*',
          Authorization: `bearer ${''}`,
        },
      }).then((res) => {
        if (res.data.data) {
          // this.editData = res.data.data;
          this.$store.dispatch('courseModule/getEditData', res.data.data);
          this.tempSyll = this.course.chapter.map((item, i) => ({ id: i * 2, title: item.title, section: item.chaperItem }));

          this.tempSyll.forEach((item, idx) => {
            const sectionArr = item.section.map((el, i) => ({
              id: `${idx}-${i}`, title: el.title, video: el.videoUrl, desc: el.content, lock: el.lock, time: el.duration, isHomeWork: false,
            }));
            item.section = [...sectionArr];
          });
          this.$store.dispatch('syllabusModule/getSyllData', this.tempSyll);
        }
      }).catch((err) => {
        this.goNext = true;
        this.$router.push('/error');
        this.$log.debug(err.response);
      });
    },
    backToMyCourse() {
      this.$router.push('/dashboard/courses');
    },
    mouseMove(e) {
      const vm = this;
      let posx = 0;
      let posy = 0;
      if (!e) var e = window.event;
      if (e.pageX || e.pageY) {
        posx = e.pageX - document.documentElement.scrollLeft - document.body.scrollLeft;
        posy = e.pageY - document.documentElement.scrollTop - document.body.scrollTop;
      } else if (e.clientX || e.clientY) {
        posx = e.clientX;// + document.body.scrollLeft+ document.documentElement.scrollLeft;
        posy = e.clientY;// + document.body.scrollTop + document.documentElement.scrollTop;
      }
      if (posy > (window.innerHeight - 120)) {
        vm.showBtn = true;
      } else if (posy <= (window.innerHeight - 120)) {
        vm.showBtn = false;
      }
      const editBox = document.querySelector('.editBox');
      const slideInAt = (window.scrollY + window.innerHeight) - editBox.offsetHeight / 2;
      if (slideInAt > editBox.offsetTop) {
        vm.showBtn = false;
      }
    },
  },
  computed: {
    ...mapGetters(['userName', 'Avatar', 'userId', 'role', 'exp', 'userAuth', 'LoginProvider']),
    ...mapGetters('courseModule', ['course']),
    ...mapGetters('syllabusModule', ['syllabus']),
  },
  beforeRouteLeave(to, from, next) {
    const vm = this;
    if (vm.goNext || vm.change == false) {
      next();
    } else {
      let text = '';
      if (this.mode == 'edit') {
        text = '尚未儲存這次的更新，請按儲存以儲存您的修改。';
      } else {
        text = '提醒您，上架課程將不會暫存您的變更，需完成至第四步驟完成發布，本系統即會儲存並發布您的課程。';
      }
      Swal.fire({
        title: '',
        text,
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: '取消',
        confirmButtonText: '離開',
      }).then((result) => {
        if (result.isConfirmed) {
          next();
        }
      });
    }
  },
  created() {
    this.$log.debug(this.min);
    this.$log.debug(this.sec);
    if (this.$route.params.id) {
      this.id = this.$route.params.id;
      this.mode = 'edit';
      this.getEditData();
    } else {
      // this.getDatafromLocal();
      this.$log.debug(this.syllabus);
      localStorage.setItem('step', '2');
      if (this.syllabus.length == 0 || !this.syllabus) {
        this.$store.dispatch('syllabusModule/getSyllData', [{
          id: Math.floor(Date.now()),
          title: '',
          section: [{
            id: Math.floor(Date.now() + 10),
            title: '',
            videoUrl: '',
            videoId: '',
            desc: '',
            lock: true,
            time: '',
            isHomeWork: false,
          }],
        }]);
      }
      this.tempSyll = this.syllabus;
    }
  },
  mounted() {
    const vm = this;
    window.addEventListener('beforeunload', (e) => {
      const confirmationMessage = '你還沒有完成你的文章，就這樣離開了嗎？';

      (e || window.event).returnValue = confirmationMessage; // Gecko + IE
      return confirmationMessage; // Webkit, Safari, Chrome
    });
    window.addEventListener('mousemove', vm.mouseMove);
  },
  destroyed() {
    const vm = this;
    window.removeEventListener('beforeunload', (e) => {
      const confirmationMessage = '你還沒有完成你的文章，就這樣離開了嗎？';

      (e || window.event).returnValue = confirmationMessage; // Gecko + IE
      return confirmationMessage; // Webkit, Safari, Chrome
    });
    window.removeEventListener('mousemove', vm.mouseMove);
  },
};
</script>

<style lang="scss" scoped>
  .invalid .text-danger {
    text-align: left;
    width: 100%;
    display: block;
    font-size: 12px;
  }

  .invalid input {
    border-bottom: 1px #EB0600 solid;
    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(252, 67, 21, 0.25);
    }
  }

  .invalid.text-danger {
    text-align: left;
    width: 100%;
    display: block;
    font-size: 12px;
  }
  .upload {
    &__progress {
      background: rgb(197, 197, 197);
      width: 6rem;
      height: .8rem;
      border-radius: 10px;
    }
    &__progressBar {
        background: #012970;
        width: 0%;
        height: inherit;
        border-radius: 10px;
      }
  }
</style>
